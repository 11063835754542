import axios from "axios";
import {
  GATSBY_API_BASE_URL,
  GATSBY_EU_API_BASE_URL,
  GATSBY_APAC_API_BASE_URL,
} from "gatsby-env-variables";

const baseUrl = GATSBY_API_BASE_URL;
const euBaseUrl = GATSBY_EU_API_BASE_URL;
const apacBaseUrl = GATSBY_APAC_API_BASE_URL;

const axiosRF = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
});

const axiosEURF = axios.create({
  baseURL: euBaseUrl,
  timeout: 30000,
});

const axiosAPACRF = axios.create({
  baseURL: apacBaseUrl,
  timeout: 30000,
});

const getAxiosAgentByRegionDomain = (regionDomain) => {
  switch (regionDomain) {
    case "bru":
      return axiosEURF;
    case "syd":
      return axiosAPACRF;
    case "lax":
      return axiosRF;
    default:
      return axiosRF;
  }
};

export const getCountries = () => {
  return axiosRF.get("/countries");
};

export const postRegisterForm = (form, regionDomain) => {
  const axiosAgent = getAxiosAgentByRegionDomain(regionDomain);
  return axiosAgent.post("/register", form);
};

export const login = (emailAndPwd, regionDomain) => {
  const axiosAgent = getAxiosAgentByRegionDomain(regionDomain);
  return axiosAgent.post("/login", emailAndPwd);
};

export const forgotPwd = async (email) => {
  // in dev (when baseUrl is same with eubaseUrl)
  // a little cheat actually  baseUrl === euBaseUrl === apacBaseUrl
  if (baseUrl === euBaseUrl) {
    return axiosRF.post("/forgot-password", email);
  }
  // in production (when baseUrl is different from eubaseUrl)
  const requestArr = [
    axiosRF.post("/forgot-password", email).catch((err) => err.response),
    axiosEURF.post("/forgot-password", email).catch((err) => err.response),
    axiosAPACRF.post("/forgot-password", email).catch((err) => err.response),
  ];
  const resArr = await Promise.all(requestArr);
  const successRes = resArr.find((res) => res.status !== 400);
  if (successRes) return successRes;
  throw { response: resArr[0] };
};

export const resetPwd = (form, regionDomain) => {
  const axiosAgent = getAxiosAgentByRegionDomain(regionDomain);
  return axiosAgent.post("/reset-password", form);
};

export const sendEmailOtp = (email, regionDomain) => {
  const axiosAgent = getAxiosAgentByRegionDomain(regionDomain);
  return axiosAgent.post("/otp/register", email);
};
